import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import introImg from '../assets/img/intro.jpg'
import Layout from '../components/layout'
import { graphql, useStaticQuery, Link } from 'gatsby'
import SlidesComponent from '../components/slides'
import ReactMarkdown from 'react-markdown'



const IndexPage = () => {
  const data = useStaticQuery(indexQuery)

  return (
  <>
    <Layout title={'Harmony Road'} subtitle={'Baptist Church'}>
      <section className="page-section clearfix">
        <Container>
          <div className="intro">
            <img className="intro-img img-fluid mb-3 mb-md-0 rounded" src={introImg} alt="" />
            <div className="intro-text left-0 text-center bg-faded p-5 rounded">
              <div id="special-events">

              </div>
              <h2 className="section-heading mb-4">
                <span className="section-heading-upper">This</span>
                <span className="section-heading-lower">Sunday Morning</span>
              </h2>
              <p className="mb-3">Come join us for worship!</p>
              <div style={{ backgroundColor: '#ffc939', borderRadius: '5px', padding: '8px' }}>
                In response to recent COVID 19 concerns and in accordance with the Canadian government guidelines,
                both our services have been cancelled. For the time being, the sermon will be streamed live from
                <a id="fbLink" target="_blank" href="<?php include __DIR__.'/php/youtube_rss.php'; ?>"> Youtube</a>.
                Please visit <a href="/coronavirus">THIS PAGE</a> for updates and further info on our response to COVID 19.</div>
              {/* <!-- DYNAMICALLY GENERATED TIMES FROM GOOGLECAL.PHP --> */}
              <p id="sunday-morning" className="mb-3">

              </p>
              {/* <!-- DYNAMICALLY GENERATED TIMES FROM YOUTUBE_RSS.PHP --> */}
              <div id="youtube-rss">

              </div>
              {/* <!--https://www.youtube.com/feeds/videos.xml?channel_id=UCstkgHSlZfVWcab6dLmYqpg--> */}
              {/* <!--<div className="intro-button mx-auto">--> */}
              {/* <!--  <a style="font:'Oxygen',sans-serif" className="btn btn-primary btn-xl" href="http://www.google.com/maps?saddr=My+Location&amp;daddr=Harmony+Road+Baptist+Church,+226+Harmony+Rd+S,+Oshawa,+ON+L1H+6T7" target="_blank">GET DIRECTIONS</a>--> */}
              {/* <!--</div>--> */}
            </div>
          </div>
        </Container>
      </section>

      <section className="page-section about-heading">
        <Container>
          <SlidesComponent />
        </Container>
      </section>

      <section className="page-section cta">
        <Container>
          <Row>
            <div className="col-xl-9 mx-auto">
              <div className="cta-inner text-center rounded remark-content">
                <h2 className="section-heading mb-5">
                  <span className="section-heading-upper">What's Happening</span>
                  <span className="section-heading-lower">This Week</span>
                </h2>
                <ul id="event-list" className="list-unstyled list-hours mb-5 text-left mx-auto">

                  {/* <?php include __DIR__ . '/php/googlecal.php'; ?> */}

                  {/* <!-- <li>
                <h2>DAY</h2>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  <span>Item</span>
                  <span className="ml-auto">time</span>
                </li> --> */}

                </ul>

                <ReactMarkdown source={data.strapiVisionStatement.body} />
              </div>
            </div>
          </Row>
        </Container>
      </section>

      {/* <?php include __DIR__ . '/php/footer.php'; ?> */}

      {/* <!-- Carousel --> */}
      <script src="js/jquery.waypoints.min.js">

      </script>
      <script src="js/jquery.flexslider-min.js">

      </script>
      <script src="js/main.js">

      </script>
    </Layout>
  </>
)
}

export default IndexPage


const indexQuery = graphql`{
  strapiVisionStatement {
    body
  }
}
`