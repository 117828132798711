import React from 'react'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'
import '../assets/css/slider.css'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'


const today = new Date() * 1;

const isValidSlide = (slide) => {
  let isValid = true;
  if (slide.start_date && today < Date.parse(slide.start_date)) {
    isValid = false;
  } else if (slide.end_date && today > Date.parse(slide.end_date)) {
    isValid = false;
  }
  return isValid;
}

export default () => {
  let slides = useStaticQuery(slideQuery).allStrapiBanner.nodes.filter(s => { return isValidSlide(s) })

  return (
    <div>
      <Slider className="slider-wrapper">
        {slides.map((item, index) => (
          <div
            key={index}
            className={'slider-content ' + item.layout}
            style={{ background: `url('${item.background ? item.background.publicURL : ''}') no-repeat center center` }}
          >
            <div className="inner">
              <ReactMarkdown source={item.content} />
              <ButtonArray buttons={item.buttons} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}


const ButtonArray = ({ buttons }) => {
  if (buttons) {
    return (
      <>
        {buttons.map((button,index) => (
          <Button key={index} button={button}/>
        ))}
      </>
    )
  } else {
    return <></>
  }
}


const Button =({ button }) => {
  if (button && button.href && button.href.startsWith('/')) {
    return <Link to={button.href} className="btn btn-primary mx-1 mb-2">{button.label}</Link>
  } else {
    return <a href={button.href} target={button.target} className="btn btn-primary mx-1 mb-2">{button.label}</a>
  }
}


const slideQuery = graphql`{
  allStrapiBanner(
    sort: {order: ASC, fields: priority}, 
    filter: {is_active: {eq: true}}
  ) {
      nodes {
        priority
        start_date
        layout
        end_date
        content
        background { publicURL }
        buttons {
          label
          link
          external
          css_class
        }
    }
  }
}
`
